import { formatCoding } from '@iehr/core';
import { Coding } from '@iehr/fhirtypes';

export interface CodingDisplayProps {
  readonly value?: Coding;
}

export function CodingDisplay(props: CodingDisplayProps): JSX.Element {
  return <>{formatCoding(props.value)}</>;
}
