import { Title } from '@mantine/core';
import { Document, Loading, IEHRLink, useSearchResources } from '@iehr/react';
import { useParams } from 'react-router-dom';

export function BulkAppPage(): JSX.Element {
  const { resourceType } = useParams() as {
    resourceType: string;
    id: string;
  };
  const queryParams = Object.fromEntries(new URLSearchParams(location.search).entries()) as Record<string, string>;
  const ids = (queryParams.ids || '').split(',').filter((e) => !!e);
  const [questionnaires] = useSearchResources('Questionnaire', `subject-type=${resourceType}`);

  if (!questionnaires) {
    return <Loading />;
  }

  if (questionnaires.length === 0) {
    return (
      <Document>
        <Title>No apps for {resourceType}</Title>
        <IEHRLink to={`/${resourceType}`}>Return to search page</IEHRLink>
      </Document>
    );
  }

  return (
    <Document>
      <div>
        {questionnaires.map((questionnaire) => (
          <div key={questionnaire.id}>
            <h3>
              <IEHRLink to={`/forms/${questionnaire.id}?subject=` + ids.map((id) => `${resourceType}/${id}`).join(',')}>
                {questionnaire.name}
              </IEHRLink>
            </h3>
            <p>{questionnaire.description}</p>
          </div>
        ))}
      </div>
    </Document>
  );
}
