export interface LogoProps {
  readonly size: number;
  readonly fill?: string;
}

function ColorLuminance(hex: any, lum: number): string {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
}

export function Logo(props: LogoProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 50" style={{ width: props.size, height: props.size }}>
      <title>iEHR Logo</title>
      <path
        id="1-1"
        fill={props.fill ? ColorLuminance(props.fill, 0.5) : '#7857c5'}
        d="m 8.45,1.68 0.03,7.00 -7.58,3.60 C 1.82,7.67 4.21,4.04 8.45,1.68 Z"
      />
      <path
        id="1-2"
        fill={props.fill ? ColorLuminance(props.fill, 0.2) : '#33961e'}
        d="M 9.33,1.20 C 14.97,-0.33 22.30,0.68 25.06,8.69 L 17.19,12.49 9.32,8.69 Z"
      />
      <path
        id="1-3"
        fill={props.fill ? ColorLuminance(props.fill, 0) : '#40bc26'}
        d="m 41.62,1.09 v 7.59 L 33.75,12.49 25.88,8.69 c 3.27,-6.61 8.31,-9.59 15.73,-7.59 z"
      />
      <path
        id="1-4"
        fill={props.fill ? ColorLuminance(props.fill, -0.2) : '#946af9'}
        d="m 42.43,1.36 c 4.34,2.40 6.86,5.48 7.63,10.88 L 42.45,8.69 Z"
      />
      <path
        id="2-1"
        fill={props.fill ? ColorLuminance(props.fill, 0.2) : '#33961e'}
        d="M 0.76,13.41 8.90,9.49 16.77,13.29 v 7.59 l -7.86,3.79 -7.74,-3.79 C 0.80,18.34 0.60,15.83 0.76,13.41 Z"
      />
      <path
        id="2-2"
        fill={props.fill ? ColorLuminance(props.fill, 0) : '#40bc26'}
        d="m 17.60,13.22 7.86,-3.79 7.86,3.79 v 7.59 l -7.86,3.79 -7.86,-3.79 z"
      />
      <path
        id="2-3"
        fill={props.fill ? ColorLuminance(props.fill, -0.2) : '#946af9'}
        d="m 34.17,13.29 7.86,-3.79 8.15,3.79 c 0.25,2.56 0.14,5.09 -0.28,7.59 l -7.86,3.79 -7.86,-3.79 z"
      />
      <path
        id="3-1"
        fill={props.fill ? ColorLuminance(props.fill, 0.2) : '#33961e'}
        d="m 1.50,22.12 6.99,3.36 v 7.59 l -0.19,0.04 C 4.87,29.90 2.73,26.52 1.50,22.12 Z"
      />
      <path
        id="3-2"
        fill={props.fill ? ColorLuminance(props.fill, 0) : '#40bc26'}
        d="m 9.32,25.49 7.86,-3.79 7.86,3.79 v 7.59 L 17.19,36.88 9.32,33.09 Z"
      />
      <path
        id="3-3"
        fill={props.fill ? ColorLuminance(props.fill, -0.2) : '#946af9'}
        d="m 25.88,25.49 7.86,-3.79 7.86,3.79 v 7.59 l -7.86,3.79 -7.86,-3.79 z"
      />
      <path
        id="3-4"
        fill={props.fill ? ColorLuminance(props.fill, -0.5) : '#7857c5'}
        d="m 42.45,25.49 7.29,-3.57 c -1.54,4.57 -3.61,8.18 -7.41,11.13 z"
      />
      <path
        id="4-1"
        fill={props.fill ? ColorLuminance(props.fill, 0) : '#40bc26'}
        d="m 9.05,33.89 7.72,3.79 v 2.83 z"
      />
      <path
        id="4-2"
        fill={props.fill ? ColorLuminance(props.fill, -0.2) : '#946af9'}
        d="m 17.60,37.68 7.86,-3.79 7.86,3.79 -0.01,3.63 c -2.98,2.59 -5.61,5.41 -7.91,8.42 -1.89,-3.29 -4.73,-5.94 -7.74,-8.46 z"
      />
      <path
        id="4-3"
        fill={props.fill ? ColorLuminance(props.fill, -0.5) : '#7857c5'}
        d="m 34.17,37.68 7.86,-3.79 -7.85,6.63 z"
      />
    </svg>
  );
}
