import { ActionIcon, Grid, Menu, rem, Textarea, TextareaProps } from '@mantine/core';
import { BaseEditor } from './BaseEditor';
import { useEffect, useState } from 'react';
import { IconEye, IconHtml, IconLetterCase, IconMarkdown } from '@tabler/icons-react';

export interface TextEditorProps extends TextareaProps {
  readonly editorType?: 'string' | 'markdown' | 'html';
  readonly onUpdate?: (content: string) => void;
}

export function TextEditor(props: TextEditorProps): JSX.Element {
  const { onUpdate, editorType, ...propsRest } = props;
  const defaultValue = props.defaultValue;
  const [initialized, setInitialized] = useState<boolean>(false);
  const [type, setType] = useState<'string' | 'markdown' | 'html'>(props.editorType ?? 'string');

  useEffect(() => {
    if (!initialized && !editorType && defaultValue) {
      setInitialized(true);
      if (defaultValue.toString().includes('/>')) {
        setType('html');
      } else if (defaultValue.toString().includes('##')) {
        setType('markdown');
      }
    }
  }, [initialized, defaultValue, editorType]);

  return (
    <Grid>
      <Grid.Col span={'auto'}>
        {type === 'string' && <Textarea onChange={(e) => onUpdate?.(e.currentTarget.value)} {...propsRest} />}
        {type === 'markdown' && (
          <BaseEditor
            editorType={type}
            content={props.defaultValue as string}
            onUpdate={(e) => onUpdate?.(e.editor.storage.markdown.getMarkdown())}
          />
        )}
        {type === 'html' && (
          <BaseEditor
            editorType={type}
            content={props.defaultValue as string}
            onUpdate={(e) => onUpdate?.(e.editor.getHTML())}
          />
        )}
      </Grid.Col>

      <Grid.Col span={'content'}>
        {!props.editorType && (
          <Menu>
            <Menu.Target>
              <ActionIcon size="xs" radius="xl" variant="transparent">
                <IconEye />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              {type !== 'string' && (
                <Menu.Item
                  onClick={() => setType('string')}
                  leftSection={<IconLetterCase style={{ width: rem(14), height: rem(14) }} />}
                >
                  Text
                </Menu.Item>
              )}
              {type !== 'html' && (
                <Menu.Item
                  onClick={() => setType('html')}
                  leftSection={<IconHtml style={{ width: rem(14), height: rem(14) }} />}
                >
                  HTML
                </Menu.Item>
              )}
              {type !== 'markdown' && (
                <Menu.Item
                  onClick={() => setType('markdown')}
                  leftSection={<IconMarkdown style={{ width: rem(14), height: rem(14) }} />}
                >
                  Markdown
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        )}
      </Grid.Col>
    </Grid>
  );
}
