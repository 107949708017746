import { getQuestionnaireAnswers } from '@iehr/core';
import {
  QuestionnaireItem,
  QuestionnaireResponse,
  QuestionnaireResponseItemAnswer,
} from '@iehr/fhirtypes';
import { isQuestionEnabled } from '../utils/questionnaire';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { modals } from '@mantine/modals';
import { Stack, Text } from '@mantine/core';

export function flattenQuestionnaire(
  items: QuestionnaireItem[] | undefined,
  response: QuestionnaireResponse | undefined
): QuestionnaireItem[] {
  return !items
    ? []
    : items
        .filter((item) => item.required && isQuestionEnabled(item, response))
        .reduce<
          QuestionnaireItem[]
        >((pre, cur) => (cur.item ? [...pre, ...flattenQuestionnaire(cur.item, response)] : [...pre, cur]), []);
}

export function checkRequiredFields(
  items: QuestionnaireItem[],
  response: QuestionnaireResponse | undefined
): (string | undefined)[] {
  if (!items) {
    return [];
  }
  const reqItems = flattenQuestionnaire(items, response);

  const answers = getQuestionnaireAnswers(response || ({} as QuestionnaireResponse));
  return reqItems
    .filter(
      (item) =>
        !answers[item.linkId] || !Object.keys(answers[item.linkId]).length || !Object.values(answers[item.linkId])[0]
    )
    .map((item) => item.text);
}

export function getCustomValidators(
  item: QuestionnaireItem,
  answers: Record<string, QuestionnaireResponseItemAnswer>
): { item: QuestionnaireItem; answer: QuestionnaireResponseItemAnswer; validator: string }[] {
  const vdator = item?.extension?.find((i) => i.url.includes('questionnaire-item-validator'))?.valueString;
  let validators = vdator
    ? [
        {
          item: item,
          answer: answers[item.linkId],
          validator: vdator,
        },
      ]
    : [];
  if (item?.item) {
    validators = item?.item.reduce((pre, cur) => [...pre, ...getCustomValidators(cur, answers)], validators);
  }

  return validators.filter((v) => v.validator);
}

const phoneUtil = PhoneNumberUtil.getInstance();
export const isPhoneValid = (phone: string): boolean => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch {
    return false;
  }
};
export function isEmailValid(email: string): boolean {
  const validEmailRegex =
    /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (!email) {
    return false;
  }

  const emailParts = email.split('@');

  if (emailParts.length !== 2) {
    return false;
  }

  const account = emailParts[0];
  const domain = emailParts[1];

  if (account.length > 64) {
    return false;
  } else if (domain.length > 255) {
    return false;
  }

  const domainParts = domain.split('.');

  if (domainParts.some((part) => part.length > 63)) {
    return false;
  }

  return validEmailRegex.test(email);
}

export function validateResponces(
  items?: QuestionnaireItem[],
  response?: QuestionnaireResponse,
  _modal?: boolean
): boolean {
  if (items) {
    for (const item of items) {
      if (!validateResponce(item, response)) {
        return false;
      }
    }
  }

  return true;
}
function validateResponce(item: QuestionnaireItem, response?: QuestionnaireResponse, modal?: boolean): boolean {
  //get all the validators (childern as well)
  const answers = getQuestionnaireAnswers(response || ({} as QuestionnaireResponse));
  const validators = getCustomValidators(item, answers);

  for (const validator of validators) {
    //call validator
    if (
      !(
        validator.validator as unknown as (
          item: QuestionnaireItem,
          answer: QuestionnaireResponseItemAnswer,
          response?: QuestionnaireResponse
        ) => boolean
      )(validator.item, validator.answer, response)
    ) {
      return false;
    }
  }

  const remainingRequiredFields = checkRequiredFields([item], response);
  if (remainingRequiredFields.length) {
    if (modal) {
      modals.open({
        title: 'Incomplete Form',
        centered: true,
        children: (
          <Stack>
            <Text>
              Please provide a valid response to all questions marked by a red star (
              <Text span color="red">
                *
              </Text>
              )
            </Text>
          </Stack>
        ),
      });
    }

    return false;
  }

  return true;
}
