import { createReference } from '@iehr/core';
import { Annotation } from '@iehr/fhirtypes';
import { useIEHRProfile } from '@iehr/react-hooks';
import { useState } from 'react';
import { ComplexTypeInputProps } from '../ResourcePropertyInput/ResourcePropertyInput.utils';
import { TextEditor } from '../TextEditor/TextEditor';

export interface AnnotationInputProps extends ComplexTypeInputProps<Annotation> {}

export function AnnotationInput(props: AnnotationInputProps): JSX.Element {
  const author = useIEHRProfile();
  const [value, setValue] = useState<Annotation>(props.defaultValue || ({} as Annotation));

  function setText(text: string): void {
    const newValue: Annotation = text
      ? {
          text,
          authorReference: author && createReference(author),
          time: new Date().toISOString(),
        }
      : ({} as Annotation);

    setValue(newValue);
    if (props.onChange) {
      props.onChange(newValue);
    }
  }

  return (
    <TextEditor
      name={props.name}
      placeholder="Annotation text"
      defaultValue={value.text}
      editorType="markdown"
      spellCheck={true}
      onUpdate={(u) => setText(u)}
    />
  );
}
