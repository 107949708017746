import { IEHRClient, ProfileResource } from '@iehr/core';
import { createContext, useContext } from 'react';

export const reactContext = createContext(undefined as IEHRContext | undefined);

export type IEHRNavigateFunction = (path: string) => void;

export interface IEHRContext {
  iehr: IEHRClient;
  navigate: IEHRNavigateFunction;
  profile?: ProfileResource;
  loading: boolean;
}

/**
 * Returns the IEHRContext instance.
 * @returns The IEHRContext instance.
 */
export function useIEHRContext(): IEHRContext {
  return useContext(reactContext) as IEHRContext;
}

/**
 * Returns the IEHRClient instance.
 * This is a shortcut for useIEHRContext().iehr.
 * @returns The IEHRClient instance.
 */
export function useIEHR(): IEHRClient {
  return useIEHRContext().iehr;
}

/**
 * Returns the iEHR navigate function.
 * @returns The iEHR navigate function.
 */
export function useIEHRNavigate(): IEHRNavigateFunction {
  return useIEHRContext().navigate;
}

/**
 * Returns the current iEHR user profile (if signed in).
 * This is a shortcut for useIEHRContext().profile.
 * @returns The current user profile.
 */
export function useIEHRProfile(): ProfileResource | undefined {
  return useIEHRContext().profile;
}
