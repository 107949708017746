import { IEHRClient, IEHRClientEventMap } from '@iehr/core';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { IEHRNavigateFunction, reactContext } from './IEHRProvider.context';

export interface IEHRProviderProps {
  readonly iehr: IEHRClient;
  readonly navigate?: IEHRNavigateFunction;
  readonly children: ReactNode;
}

const EVENTS_TO_TRACK = [
  'change',
  'storageInitialized',
  'storageInitFailed',
  'profileRefreshing',
  'profileRefreshed',
] satisfies (keyof IEHRClientEventMap)[];

/**
 * The IEHRProvider component provides iEHR context state.
 *
 * iEHR context includes:
 *   1) iehr - iEHR client library
 *   2) profile - The current user profile (if signed in)
 * @param props - The IEHRProvider React props.
 * @returns The IEHRProvider React node.
 */
export function IEHRProvider(props: IEHRProviderProps): JSX.Element {
  const iehr = props.iehr;
  const navigate = props.navigate ?? defaultNavigate;

  const [state, setState] = useState({
    profile: iehr.getProfile(),
    loading: iehr.isLoading(),
  });

  useEffect(() => {
    function eventListener(): void {
      setState((s) => ({
        ...s,
        profile: iehr.getProfile(),
        loading: iehr.isLoading(),
      }));
    }

    for (const event of EVENTS_TO_TRACK) {
      iehr.addEventListener(event, eventListener);
    }
    return () => {
      for (const event of EVENTS_TO_TRACK) {
        iehr.removeEventListener(event, eventListener);
      }
    };
  }, [iehr]);

  const iehrContext = useMemo(
    () => ({
      ...state,
      iehr,
      navigate,
    }),
    [state, iehr, navigate]
  );

  return <reactContext.Provider value={iehrContext}>{props.children}</reactContext.Provider>;
}

/**
 * The default "navigate" function which simply uses window.location.href.
 * @param path - The path to navigate to.
 */
function defaultNavigate(path: string): void {
  window.location.assign(path);
}
