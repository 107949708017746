import { stringify } from '@iehr/core';
import { Reference } from '@iehr/fhirtypes';
import { IEHRLink } from '../IEHRLink/IEHRLink';

export interface ReferenceDisplayProps {
  readonly value?: Reference;
  readonly link?: boolean;
}

export function ReferenceDisplay(props: ReferenceDisplayProps): JSX.Element | null {
  if (!props.value) {
    return null;
  }

  const displayString = props.value.display || props.value.reference || stringify(props.value);

  // The "link" prop defaults to "true"; undefined is treated as "true"
  // To disable the link, it must be explicitly "false"
  if (props.link !== false && props.value.reference) {
    return <IEHRLink to={props.value}>{displayString}</IEHRLink>;
  } else {
    return <>{displayString}</>;
  }
}
